html {
  background-color: #ddccc1;
}

#root {
  background-color: #ddccc1;
}

.back-button {
  width: 100px;
}

.navbar {
  background-color: #d8c9bc;
}

.nav-title {
  color: #d38c61;
}

a {
  text-decoration: none;
  color: inherit;
}

/****************************************************
**************REACT CHECKBOX TREE CSS****************
****************************************************/

.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;
}

.react-checkbox-tree > ol {
  flex: 1 1 auto;
}

.react-checkbox-tree ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.react-checkbox-tree ol ol {
  padding-left: 24px;
}

.react-checkbox-tree button {
  line-height: normal;
  color: inherit;
}

.react-checkbox-tree button:focus {
  outline: none;
}

.react-checkbox-tree button:disabled {
  cursor: not-allowed;
}

.react-checkbox-tree .rct-bare-label {
  cursor: default;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
}

.react-checkbox-tree label:hover {
  background: rgba(51, 51, 204, 0.1);
}

.react-checkbox-tree label:active {
  background: rgba(51, 51, 204, 0.15);
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: none;
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 5px;
}

.react-checkbox-tree .rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-family: 'Font Awesome 5 Free', 'FontAwesome', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
}

.rct-disabled > .rct-text > label {
  opacity: 0.75;
  cursor: not-allowed;
}

.rct-disabled > .rct-text > label:hover {
  background: transparent;
}

.rct-disabled > .rct-text > label:active {
  background: transparent;
}

.rct-text {
  display: flex;
  align-items: center;
}

.rct-options {
  flex: 0 0 auto;
  margin-left: 0.5rem;
  text-align: right;
}

.rct-option {
  opacity: 0.75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;
}

.rct-option:hover {
  opacity: 1;
}

.rct-option + .rct-option {
  margin-left: 2px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  display: inline-block;
  margin: 0;
  width: 14px;
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;
}

.rct-collapse.rct-collapse-btn {
  cursor: pointer;
}

.rct-collapse > .rct-icon-expand-close {
  opacity: 0.5;
}

.rct-collapse > .rct-icon-expand-close:hover {
  opacity: 1;
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;
}

.rct-node-clickable:hover {
  background: rgba(51, 51, 204, 0.1);
}

.rct-node-clickable:focus {
  outline: 0;
  background: rgba(51, 51, 204, 0.2);
}

.rct-node-icon {
  color: #33c;
}

.rct-title {
  padding: 0 5px;
}

.rct-icons-fa4 .rct-icon-expand-close::before {
  content: '\F054';
}

.rct-icons-fa4 .rct-icon-expand-open::before {
  content: '\F078';
}

.rct-icons-fa4 .rct-icon-uncheck::before {
  content: '\F096';
}

.rct-icons-fa4 .rct-icon-check::before {
  content: '\F046';
}

.rct-icons-fa4 .rct-icon-half-check::before {
  opacity: 0.5;
  content: '\F046';
}

.rct-icons-fa4 .rct-icon-leaf::before {
  content: '\F016';
}

.rct-icons-fa4 .rct-icon-parent-open::before {
  content: '\F115';
}

.rct-icons-fa4 .rct-icon-parent-close::before {
  content: '\F114';
}

.rct-icons-fa4 .rct-icon-expand-all::before {
  content: '\F0FE';
}

.rct-icons-fa4 .rct-icon-collapse-all::before {
  content: '\F146';
}

.rct-icons-fa5 .rct-icon-expand-close::before {
  font-weight: 900;
  content: '\F054';
}

.rct-icons-fa5 .rct-icon-expand-open::before {
  font-weight: 900;
  content: '\F078';
}

.rct-icons-fa5 .rct-icon-uncheck::before {
  content: '\F0C8';
}

.rct-icons-fa5 .rct-icon-check::before {
  content: '\F14A';
}

.rct-icons-fa5 .rct-icon-half-check::before {
  opacity: 0.5;
  content: '\F14A';
}

.rct-icons-fa5 .rct-icon-leaf::before {
  content: '\F15B';
}

.rct-icons-fa5 .rct-icon-parent-open::before {
  content: '\F07C';
}

.rct-icons-fa5 .rct-icon-parent-close::before {
  content: '\F07B';
}

.rct-icons-fa5 .rct-icon-expand-all::before {
  content: '\F0FE';
}

.rct-icons-fa5 .rct-icon-collapse-all::before {
  content: '\F146';
}

